import jwt from '@/auth/jwt/useJwt';
import { removeEmptyString } from '@/common/utils';

export function teamList(condition) {
  return jwt.axiosIns.get('/teams', {
    params: {
      ...condition,
    },
  });
}

export function teamListCampaign(condition) {
  return jwt.axiosIns.get('/teams/campaigns', {
    params: {
      ...condition,
    },
  });
}
export function teamListLender(condition) {
  return jwt.axiosIns.get('/teams/users', {
    params: {
      ...condition,
    },
  });
}

export function teamShow(id) {
  return jwt.axiosIns.get(`/teams/${id}`);
}

export function teamShowCampaign(id) {
  return jwt.axiosIns.get(`/teams/${id}/campaigns`);
}

export function teamRemove(id) {
  return jwt.axiosIns.delete(`/teams/${id}`);
}

export function teamCreate(data) {
  data = removeEmptyString(data);
  return jwt.axiosIns.post('teams', data);
}

export function teamUpdate(id, data) {
  data = removeEmptyString(data);
  return jwt.axiosIns.put(`/teams/${id}`, data);
}
