import jwt from '@/auth/jwt/useJwt';
import { removeEmptyString } from '@/common/utils';

export function campaignList(condition) {
  return jwt.axiosIns.get('/campaigns', {
    params: {
      ...condition,
    },
  });
}

export function campaignListTeam(condition) {
  return jwt.axiosIns.get('/campaigns/teams', {
    params: {
      ...condition,
    },
  });
}

export function campaignShow(id) {
  return jwt.axiosIns.get(`/campaigns/${id}`);
}

export function campaignShowTeam(id) {
  return jwt.axiosIns.get(`/campaigns/${id}/teams`);
}

export function campaignRemove(id) {
  return jwt.axiosIns.delete(`/campaigns/${id}`);
}

export function campaignCreate(data) {
  data = removeEmptyString(data);
  return jwt.axiosIns.post('campaigns', data);
}

export function campaignUpdate(id, data) {
  data = removeEmptyString(data);
  return jwt.axiosIns.put(`/campaigns/${id}`, data);
}

export function campaignAutocall(id, team, relatedCall, content, relatedContent) {
  return jwt.axiosIns.post(`/campaigns/${id}/autocall`, {
    team,
    relatedCall,
    content,
    relatedContent,
  });
}

export function campaignTransfer(id, desId) {
  return jwt.axiosIns.post(`/campaigns/${id}/transfer`, {
    newCampaignId: desId,
  });
}
