import jwt from '@/auth/jwt/useJwt';
import { removeEmptyString } from '@/common/utils';

export function show(id) {
  return jwt.axiosIns.get(`users/${id}`);
}

export function remove(id) {
  return jwt.axiosIns.delete(`users/${id}`);
}

export function create(data) {
  data = removeEmptyString(data);
  return jwt.axiosIns.post('users', data);
}

export function edit(id, data) {
  data = removeEmptyString(data);
  return jwt.axiosIns.put(`users/${id}`, data);
}

export function editStatus(id, status) {
  return jwt.axiosIns.patch(`users/${id}`, { status });
}

export function list(pagination) {
  return jwt.axiosIns.get('users', {
    params: {
      page: 1,
      limit: 10,
      ...pagination,
    },
  });
}

export function updatePermissions(roleId, permissions) {
  return jwt.axiosIns.put(`/users/${roleId}`, { permissions });
}

export function updateRoles(staffId, roles) {
  return jwt.axiosIns.put(`/users/${staffId}`, { roles });
}

export function teamList(condition) {
  return jwt.axiosIns.get('/users/teams', {
    params: {
      ...condition,
    },
  });
}
